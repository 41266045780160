import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

function processSummary(stepLog: any) {
  // this gives an object with dates as keys
  const groups = stepLog.reduce((groups: any[], entry: any) => {
    const process = entry.process;
    if (!groups[process]) {
      groups[process] = 0;
    }
    groups[process] += +1;
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((process) => {
    return {
      process,
      count: groups[process],
    };
  });

  return groupArrays;
}

export default function TopProcesses(data: any) {
  const processSummaryArray = processSummary(data.stepHistory).sort(
    (a, b) => b.count - a.count
  );

  return (
    <React.Fragment>
      <Typography fontWeight="bold" color="primary.dark" variant="h5">
        Most Used Processes
      </Typography>
      <br />
      {processSummaryArray.length > 0 ? (
        <Stack>
          {processSummaryArray.length > 0 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b>{processSummaryArray[0].process}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {processSummaryArray[0].count} steps used
              </Typography>
            </div>
          )}
          {processSummaryArray.length > 1 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b> {processSummaryArray[1].process}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {processSummaryArray[1].count} steps used
              </Typography>
            </div>
          )}{" "}
          {processSummaryArray.length > 2 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b> {processSummaryArray[2].process}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {processSummaryArray[2].count} steps used
              </Typography>
            </div>
          )}{" "}
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant="rounded" height={70} />
          <Skeleton variant="rounded" height={70} />
        </Stack>
      )}
    </React.Fragment>
  );
}
