import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import {
  List,
  Tooltip,
  IconButton,
  Avatar,
  Stack,
  Box,
  Typography,
  Container,
  Skeleton,
  Grid,
  ListItem,
  Button,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import StepForm from "./stepForm";
import { StepFormValues, useProcessForm } from "./context/ProcessFormContext";
import StepCard from "./stepCard";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { motion, AnimatePresence } from "framer-motion";
import { Step } from "src/types/Step";

export const StepList: React.FC = () => {
  const { watch, setValue } = useProcessForm();
  const steps = watch("steps");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isPasteEnabled, setIsPasteEnabled] = useState(false);

  useEffect(() => {
    const checkClipboard = () => {
      try {
        if (!document.hasFocus()) throw Error;

        navigator.clipboard.readText().then((clipText) => {
          try {
            const configInfo = JSON.parse(clipText);
            const subjectArr = Object.keys(configInfo);
            const testArr = [
              "anchorElement",
              "title",
              "description",
              "dialogBox",
            ];
            const checker = (arr: string[], target: string[]) =>
              target.every((v: string) => arr.includes(v));
            setIsPasteEnabled(checker(subjectArr, testArr));
          } catch {
            setIsPasteEnabled(false);
          }
        });
      } catch {
        setIsPasteEnabled(false);
      }
    };

    window.addEventListener("focus", checkClipboard);
    checkClipboard();

    return () => {
      window.removeEventListener("focus", checkClipboard);
    };
  }, []);

  useEffect(() => {
    setSelectedIndex(null);
  }, [steps]);

  const handleAddStep = (index: number) => {
    const newSteps = [...steps];
    const newStep = {
      url: "",
      title: "",
      subtitle: "",
      description: "",
      id: uuidv4(),
      anchor_element_selector: "",
      anchor_element_innerText: "",
      "anchor_element_disable_outline?": false,
      context_element_selector: "",
      next_trigger: "dialogButton",
      dialog_width: 300,
      dialog_translateX: 0,
      dialog_translateY: 0,
    } as unknown as StepFormValues;
    newSteps.splice(index + 1, 0, newStep);
    setValue("steps", newSteps, { shouldValidate: true });
  };

  const handleRemoveStep = (id: string) => {
    setValue(
      "steps",
      steps.filter((step: any) => step.id !== id),
      { shouldValidate: true }
    );
    setSelectedIndex(null);
  };

  const handleStepSelect = (index: number) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    }
  };

  const handlePasteConfig = async () => {
    try {
      const clipText = await navigator.clipboard.readText();
      const configInfo = JSON.parse(clipText);
      if (selectedIndex !== null) {
        setValue(`steps.${selectedIndex}`, {
          ...steps[selectedIndex],
          ...configInfo,
        });
      }
    } catch {
      alert("Unable to paste step information");
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        flexGrow: 1,
        bgcolor: "white",
        width: "100%",
        height: "750px",
      }}
    >
      <Stack direction="row" sx={{ display: "flex", height: "100%" }}>
        <Box width="50%">
          <Typography variant="h4" color="secondary.dark">
            Step List
          </Typography>
          {steps ? (
            <List sx={{ height: "96%", mb: 2, overflow: "scroll" }}>
              <Tooltip title="Add first step" placement="right">
                <IconButton
                  onClick={() => handleAddStep(-1)}
                  sx={{ alignSelf: "flex-start" }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
              <AnimatePresence>
                {steps.map((step, index) => (
                  <motion.div
                    key={step.id}
                    initial={{ height: 0 }} // Start collapsed
                    animate={{ height: "auto" }} // Expand to full height
                    exit={{ height: 0 }} // Collapse on exit
                    transition={{ duration: 0.2 }}
                    style={{ overflow: "hidden" }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <ListItem key={index}>
                          <StepCard
                            index={index}
                            step={step as Step}
                            isSelected={selectedIndex === index}
                            onSelect={() => handleStepSelect(index)}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Remove step" placement="bottom">
                          <IconButton
                            sx={{ p: 0 }}
                            aria-label="remove step"
                            onClick={() =>
                              handleRemoveStep(step.id || index?.toString())
                            }
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Tooltip title="Add a step" placement="right">
                          <IconButton
                            aria-label="add a step"
                            onClick={() => handleAddStep(index)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </motion.div>
                ))}
              </AnimatePresence>
            </List>
          ) : (
            <React.Fragment>
              {[...Array(4)].map(() => (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="20%"
                  sx={{ m: 2 }}
                />
              ))}
            </React.Fragment>
          )}
        </Box>

        <Box width="2%"></Box>

        <Box width="48%">
          <Stack
            direction="row"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="primary.dark"
          >
            <Typography variant="h4" color="secondary.dark" mr="3px">
              Step
            </Typography>
            {selectedIndex !== null && (
              <Avatar
                variant="rounded"
                sx={{
                  backgroundColor: "secondary.dark",
                  my: "3px",
                }}
              >
                {selectedIndex + 1}
              </Avatar>
            )}
            <Typography variant="h4" color="secondary.dark" ml="3px">
              Details
            </Typography>
          </Stack>

          {steps ? (
            <Box sx={{ height: "95%", mb: 2, overflow: "scroll" }}>
              <Button
                disabled={!isPasteEnabled || typeof selectedIndex !== "number"}
                variant="outlined"
                size="small"
                sx={{
                  m: 1,
                }}
                color="secondary"
                onClick={handlePasteConfig}
              >
                Paste from Clipboard
              </Button>
              <StepForm stepIndex={selectedIndex} />
            </Box>
          ) : (
            <Skeleton
              variant="rounded"
              width="100%"
              height="93%"
              sx={{ m: 2 }}
            />
          )}
        </Box>
      </Stack>
    </Container>
  );
};
