import React from "react";
import {
  Card,
  Grid,
  Stack,
  Typography,
  Box,
  Tooltip,
  ToggleButton,
  Slider,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";

interface DialogBoxControlsProps {
  stepPath: string;
  disabled?: boolean;
}

export const DialogBoxControls: React.FC<DialogBoxControlsProps> = ({
  stepPath,
  disabled = false,
}) => {
  const { watch, setValue } = useFormContext();

  const disableOutline = watch(`${stepPath}.dialog_disable_outline?`, false);

  const defalutValues = {
    maxX: 400,
    minX: -400,
    maxY: 300,
    minY: -300,
  };

  const handleOutlineToggle = () => {
    setValue(`${stepPath}..dialog_disable_outline?`, !disableOutline, {
      shouldValidate: true,
    });
  };

  const handleChangeX = (_: Event, newValue: number | number[]) => {
    setValue(`${stepPath}.dialog_translateX`, newValue, {
      shouldDirty: true,
    });
  };

  const handleChangeY = (_: Event, newValue: number | number[]) => {
    setValue(`${stepPath}.dialog_translateY`, -newValue, {
      shouldDirty: true,
    });
  };

  function valueLabelFormatX(value: number) {
    return `${value} px`;
  }
  function valueLabelFormatY(value: number) {
    return `${-value} px`;
  }

  return (
    <Box width="100%" pb={1}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Slider
            name={`${stepPath}.dialog_translateX`}
            // defaultValue={defaultValues.translateX}
            track={false}
            step={10}
            color="secondary"
            value={watch(`${stepPath}.dialog_translateX`, 0) || 0}
            getAriaValueText={valueLabelFormatX}
            valueLabelFormat={valueLabelFormatX}
            valueLabelDisplay="auto"
            min={defalutValues.minX}
            max={defalutValues.maxX}
            onChange={handleChangeX}
          />
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" spacing={0}>
            <Tooltip
              placement="top-end"
              arrow
              title={disableOutline ? "Show Outline" : "Hide Outline"}
            >
              <ToggleButton
                disabled={disabled}
                fullWidth
                size="small"
                value="disableOutline"
                selected={!disableOutline}
                onChange={handleOutlineToggle}
                sx={{
                  p: "5px",
                  ml: 1,
                  borderColor: "secondary.light",
                  borderStyle: "solid",
                  borderWidth: 3,
                  "&.Mui-selected, &.Mui-selected:hover": {
                    borderColor: "secondary.main",
                  },
                }}
              >
                {disableOutline ? (
                  <DoNotDisturbIcon color="info" />
                ) : (
                  <CheckCircleOutlineIcon color="info" />
                )}
              </ToggleButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <Card
            elevation={4}
            sx={{
              transform: `translate(${
                (watch(`${stepPath}.dialog_translateX`) / defalutValues.maxX) *
                20
              }px, ${
                (watch(`${stepPath}.dialog_translateY`) / defalutValues.maxX) *
                20
              }px)`,
              m: 1,
              p: 1,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              bgcolor: "primary.light",
              boxShadow: (theme) =>
                `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${theme.palette.divider}`,
            }}
          >
            <Typography
              fontSize={24}
              fontWeight="bold"
              color="divider"
              fontStyle="italic"
              align="left"
            >
              Step
            </Typography>

            <ToggleGroup
              name={`${stepPath}.dialog_width`}
              options={[
                {
                  value: 200,
                  label: "Small",
                },
                {
                  value: 300,
                  label: "Medium",
                },
                {
                  value: 400,
                  label: "Large",
                },
              ]}
              fullWidth
              exclusive
              size="small"
              color="secondary"
              id="nextTrigger"
              aria-labelledby="buttons-group-label"
            />
          </Card>
        </Grid>
        <Grid item xs={1}>
          <Slider
            name={`${stepPath}.dialog_translateY`}
            orientation="vertical"
            track={false}
            step={10}
            color="secondary"
            value={-watch(`${stepPath}.dialog_translateY`, 0)}
            getAriaValueText={valueLabelFormatY}
            valueLabelFormat={valueLabelFormatY}
            valueLabelDisplay="auto"
            min={defalutValues.minY}
            max={defalutValues.maxY}
            onChange={handleChangeY}
          />
        </Grid>
      </Grid>
      <br />
      <Typography color="divider" variant="caption" align="center">
        Note: animation shows direction of momvement; actual displacement of
        step will be lager
      </Typography>
    </Box>
  );
};
