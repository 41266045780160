import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

interface TitleProps {
  title: string;
  description: string;
}

export default function PageTitle(props: TitleProps) {
  return (
    <Box sx={{ backgroundColor: "white", p: 2, position: "sticky" }}>
      <Typography variant="h5" color="secondary" fontWeight="bold" align="left">
        {props.title}:
        <Typography
          variant="subtitle1"
          fontStyle="italic"
          color="black"
          component={"span"}
        >
          {" "}
          {props.description}
        </Typography>
      </Typography>
    </Box>
  );
}
