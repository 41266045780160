
import React, { useEffect, useMemo } from "react";
import {
  Fab,
  FormControl,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { ProcessFC } from "./process";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { Select } from "../../../Components/ui/Select";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ProcessFormProvider,
  processFormSchema,
  ProcessFormValues,
} from "./context/ProcessFormContext";
import ProcessModal from "./libraryModals/processModal";

import { type ProcessGroup } from "src/types/ProcessGroup";
import { type Process } from "src/types/Process";

import { FetchProcess, FetchProcesses } from "src/API/getUserData";
import { Step } from "src/types/Step";
import SubmitProcessModal from "./libraryModals/submitProcessModal";

export default function ProcessGroup(props: { processGroups: ProcessGroup[] }) {
  const { processGroups } = props;

  const [processModalOpen, setProcessModalOpen] = React.useState(false);
  const [processModalInfo, setProcessModalInfo] = React.useState({
    action: "",
    processInfo: {},
  });

  const [processes, setProcesses] = React.useState<Process[]>([]);
  const [selectedProcess, setSelectedProcess] = React.useState<
    Process | undefined
  >(undefined);

  const processListOptions = useMemo(() => {
    return Object.entries(processes).map(([, processObj]) => {
      return {
        value: processObj.id,
        label: processObj.name || "",
      };
    });
  }, [processes]);

  const [selectedProcessGroup, setSelectedProcessGroup] =
    React.useState<ProcessGroup | null>(null);

  const processGroupListOptions = useMemo(() => {
    return Object.entries(processGroups).map(([, processGroupObj]) => {
      return {
        value: processGroupObj.id,
        label: processGroupObj.name || "",
      };
    });
  }, [processGroups]);

  const form = useForm<ProcessFormValues>({
    resolver: zodResolver(processFormSchema),
    defaultValues: {
      processGroup: "",
      process: {
        name: "",
        description: "",
        url: "",
        chainedProcesses: [],
      },
      steps: [] as Step[],
    },
  });

  const handleGroupSelect = (event: SelectChangeEvent<unknown>) => {
    setProcesses([]);
    setSelectedProcess(undefined);

    const selectedGroup: ProcessGroup | undefined = processGroups.find(
      (group: ProcessGroup) => group.id === event.target.value
    );
    if (selectedGroup) {
      setSelectedProcessGroup(selectedGroup);
      form.reset({
        processGroup: selectedGroup.id as string,
        steps: [],
      });

      FetchProcesses(selectedGroup.id)
        .then((result: Process[]) => {
          setProcesses(result);
        })
        .catch((err) => {});
    }
  };

  const handleProcessSelect = (event: SelectChangeEvent<unknown>) => {
    event.preventDefault();
    form.reset({
      processGroup: selectedProcessGroup?.id,
    });
    const selectedProcess: Process | undefined = processes.find(
      (process: Process) => process.id === event.target.value
    );
    if (selectedProcess) {
      setSelectedProcess(selectedProcess);
      FetchProcess(selectedProcess.id)
        .then((result: Process) => {
          form.reset({
            processGroup: selectedProcessGroup?.id,
            process: {
              description: result.description || "",
              url: result.url || "",
              name: result.name,
              id: result?.id,
              status: result.status,
              chainedProcesses: result.chained_processes || [],
            },
            steps: (result.steps as Step[]) || [],
          });
        })
        .catch((err) => {
          //handle error
        });
    }
  };

  const onSubmit = (data: ProcessFormValues) => {
    const processObj = {
      ...data.process,
      steps: data.steps,
    } as Process;

    setProcessModalInfo({
      action: selectedProcess ? "edit" : "add",
      processInfo: processObj,
    });
    setProcessModalOpen(true);
  };

  const onInvalid = (errors: any) => {
    //workaround to allow submission when validation fails ('draft' status only)
    if (form.watch("process.status") === "draft") onSubmit(form.getValues());
  };

  return (
    <FormProvider {...form}>
      <ProcessFormProvider value={form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, onInvalid)}
          noValidate={true}
        >
          <Stack direction={"row"} spacing={1} sx={{ display: "flex" }}>
            <Stack sx={{ flex: 1 }}>
              <Select
                name="processGroup"
                label="Process Group"
                onChange={handleGroupSelect}
                options={processGroupListOptions}
                disabled={!processGroups?.length}
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <Stack
                direction="row"
                width="full"
                spacing={1}
                alignItems="center"
              >
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    name="process"
                    label="Process"
                    options={processListOptions}
                    value={selectedProcess?.id || ""}
                    onChange={handleProcessSelect}
                    disabled={
                      !form.watch("processGroup") ||
                      processListOptions.length === 0
                    }
                  />
                </FormControl>

                <Tooltip title="Edit Process Name" placement="top">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setProcessModalOpen(true);
                      }}
                      disabled={!selectedProcess || !form.watch("processGroup")}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Add Process" placement="top">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedProcess(undefined);
                        form.reset({
                          processGroup: form.getValues("processGroup"),
                        });
                        setProcessModalOpen(true);
                      }}
                      disabled={!form.watch("processGroup")}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
          <br />
          {selectedProcess || form.watch("process.name") ? (
            <ProcessFC groupProcesses={processes} />
          ) : (
            <Typography fontStyle="italic" color="divider" variant="h5" py={2}>
              Select a Process to view & edit details here
            </Typography>
          )}
          {processModalOpen && (
            <SubmitProcessModal
              open={processModalOpen}
              setOpen={setProcessModalOpen}
              info={processModalInfo}
            />
          )}

          {!form.formState.isDirty ||
            (!form.formState.isValid &&
              form.watch("process.status") !== "draft" && (
                <Tooltip
                  title={"Correct errors or change to 'Draft' status to submit"}
                  placement="top-start"
                  arrow
                >
                  <Fab
                    variant="extended"
                    sx={{
                      position: "fixed",

                      bottom: "30px",
                      right: "260px",
                    }}
                    color="secondary"
                  >
                    <HelpOutlineIcon />
                  </Fab>
                </Tooltip>
              ))}
          <Fab
            type="submit"
            variant="extended"
            sx={{
              position: "fixed",
              pl: 10,
              pr: 10,
              bottom: "30px",
              right: "30px",
            }}
            color="secondary"
            disabled={
              !form.formState.isDirty ||
              (!form.formState.isValid &&
                form.watch("process.status") !== "draft")
            }
          >
            Submit
          </Fab>
        </form>
      </ProcessFormProvider>
      {processModalOpen && selectedProcessGroup && (
        <ProcessModal
          open={processModalOpen}
          setOpen={setProcessModalOpen}
          processGroupId={selectedProcessGroup.id}
          process={selectedProcess || null}
          callback={() => {
            FetchProcesses(selectedProcessGroup.id)
              .then((result: Process[]) => {
                setProcesses(result);
              })
              .catch(() => {
                setProcesses([]);
              });
          }}
        />
      )}
    </FormProvider>
  );
}
