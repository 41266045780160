import React, { useContext, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import PG from "./processGroup";

import {
  Grid,
  Paper,
  Typography,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CollectionCard from "./collectionCard";
import DashboardContext from "../../../Contexts/dashboardContext";

import { NextStepList, SearchField } from "../../../Config/styling";
import PageTitle from "../title";
import { Collection } from "src/types/Collection";
import { type ProcessGroup } from "src/types/ProcessGroup";
import { FetchProcessGroups } from "src/API/getUserData";

export default function Library() {
  /* collection filter controls */
  const { orgCollections, orgProcessGroups } = useContext(DashboardContext);

  const [collectionOptions, setCollectionOptions] = React.useState<any[]>(
    orgCollections.filter((col: Collection) => col.status === "active")
  );

  const [collectionOptionsFiltered, setCollectionOptionsFiltered] =
    React.useState<any[]>(collectionOptions);

  const [selectedCollection, setSelectedCollection] = React.useState<any>(null);

  const handleSearchChange = (e: any) => {
    const tempTeamList = [...collectionOptions];

    let filteredTeamList = tempTeamList.filter((collection: any) =>
      (collection.display_name || collection.id)
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase().trim())
    );
    setCollectionOptionsFiltered(filteredTeamList);
  };

  const [collectionProcessGroups, setCollectionProcessGroups] =
    React.useState<ProcessGroup[]>(orgProcessGroups);
  useEffect(() => {
    setCollectionProcessGroups(orgProcessGroups);
  }, [orgProcessGroups]);

  const handleListItemClick = (collection: any) => {
    setSelectedCollection(collection);
    const activeCollections = orgCollections.filter(
      (col: Collection) => col.status === "active"
    );
    setCollectionOptions(activeCollections);
    setCollectionOptionsFiltered(activeCollections);

    FetchProcessGroups(collection.id)
      .then((result: ProcessGroup[]) => {
        setCollectionProcessGroups(result);
      })
      .catch((err) => {
        // handle error
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <PageTitle
        title={"Library"}
        description={
          "Manage collections of process groups and their associated processes."
        }
      />
      <CssBaseline />
      <Grid container spacing={4} p={4} id={"lib grid"}>
        {" "}
        <Grid item xs={3.5}>
          <Paper
            elevation={0}
            sx={{
              border: "lightgray solid 1px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "350px",
            }}
          >
            <Typography variant="h6" pb={2} align="left" color={"secondary"}>
              Select a collection
            </Typography>
            <SearchField
              id="outlined-search"
              label="Search for collection"
              type="search"
              size="small"
              style={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleSearchChange}
            />

            <NextStepList component="nav" aria-label="team search">
              {collectionOptionsFiltered.map((collection: Collection) => (
                <ListItemButton
                  key={collection.id}
                  selected={selectedCollection === collection}
                  onClick={() => handleListItemClick(collection)}
                >
                  <ListItemText primary={collection.name} />
                </ListItemButton>
              ))}
              {collectionOptionsFiltered.length === 0 && (
                <i>No collections found</i>
              )}
            </NextStepList>
          </Paper>
          <br />
          <CollectionCard
            collection={selectedCollection}
            processGroups={collectionProcessGroups}
          />
        </Grid>
        <Grid item xs={8.5}>
          <PG processGroups={collectionProcessGroups} />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
    </Box>
  );
}

