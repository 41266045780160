import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField } from "./FormField";

interface InputProps extends Omit<TextFieldProps, 'name'> {
  name: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, defaultValue, type, ...props }, ref) => {
    const formContext = useFormContext();

    if (!formContext) {
      throw new Error('Input must be used within a FormProvider');
    }

    return (
      <FormField
        name={name}
        defaultValue={defaultValue || ""}
        render={({ field, fieldState }) => (
          <TextField
            variant="standard"
            inputRef={ref}
            error={!!fieldState.error}
            fullWidth
            type={type === "otp" ? "number" : type} // custom case for otp that needs to be stored as a string but entered as a number
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "secondary.main",
                },
              },
              ...(props.sx || {}),
            }}
            {...field}
            {...props}
            onChange={(e) => {
              const value =
                type === "number"
                  ? e.target.value === ""
                    ? ""
                    : Number(e.target.value)
                  : e.target.value || "";
              field.onChange(value);
            }}
          />
        )}
      />
    );
  }
);

