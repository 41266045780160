import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import UserContext from "../../../Contexts/userContext";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { Collection } from "src/types/Collection";
import CollectionModal from "./adminModals/collectionModal";
import RemoveItemModal from "../dashboardModals/removeItemModal";

const rowHeaders = ["Name", "Status", "# Process Groups", "Created On", "Edit"];

export default function CollectionTable(props: { collections: Collection[] }) {
  const { memberInfo } = React.useContext(UserContext);

  const [collectionModalOpen, setCollectionModalOpen] =
    React.useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);

  const [selectedCollection, setSelectedCollection] =
    React.useState<Collection | null>(null);

  /* toggle controls */
  const [collectionList, setCollectionList] = React.useState<Collection[]>(
    props.collections.filter(
      (collection: Collection) => collection.status === "active"
    )
  );

  const [hideDisabled, setHideDisabled] = React.useState<boolean>(true);
  const showDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let filteredCollections = props.collections.filter(
        (colletion: Collection) => colletion.status === "active"
      );
      setCollectionList(filteredCollections);
    } else {
      setCollectionList(props.collections);
    }
    setHideDisabled(!hideDisabled);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography fontWeight="bold" color="primary.dark" variant="h5">
          Collections
        </Typography>
        <FormGroup sx={{ mt: -4 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={hideDisabled}
                onChange={showDisabled}
                name="disabled toggle"
              />
            }
            label={hideDisabled ? "Show Disabled" : "Hide Disabled"}
            labelPlacement="start"
          />
        </FormGroup>
      </Box>

      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table stickyHeader size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {rowHeaders.map((rowHeader: string) => (
                <TableCell
                  color="divider"
                  key={rowHeader}
                  align={rowHeader === "Edit" ? "center" : "left"}
                  sx={{ color: "secondary.dark" }}
                >
                  <b>{rowHeader}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {collectionList.length > 0 ? (
            <TableBody>
              {collectionList.map((row: Collection) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status || "active"}
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "active"
                            ? "secondary.main"
                            : "divider",
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {row.process_groups ? row.process_groups.length : 0}
                  </TableCell>
                  <TableCell>
                    {row.created_at
                      ? new Date(row.created_at).toLocaleDateString("en-US")
                      : "unknown"}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Tooltip title={"Edit Collection"} placement="left">
                      <IconButton
                        aria-label="editCollection"
                        color="secondary"
                        onClick={() => {
                          setSelectedCollection(row as Collection);
                          setCollectionModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove Collection"} placement="right">
                      <IconButton
                        disabled={memberInfo?.id === row.id}
                        aria-label="deleteCollection"
                        color="secondary"
                        onClick={() => {
                          setSelectedCollection(row as Collection);
                          setRemoveModalOpen(true);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {rowHeaders.map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(3)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "2.5rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      <br />
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        fullWidth
        onClick={() => {
          setSelectedCollection(null);
          setCollectionModalOpen(true);
        }}
      >
        Add Collection
      </Button>
      {collectionModalOpen && (
        <CollectionModal
          open={collectionModalOpen}
          setOpen={setCollectionModalOpen}
          collection={selectedCollection || null}
        />
      )}
      {removeModalOpen && selectedCollection && (
        <RemoveItemModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          itemType={"collection"}
          item={selectedCollection}
        />
      )}
    </Paper>
  );
}
