import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Skeleton from "@mui/material/Skeleton";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ProcessGroup } from "src/types/ProcessGroup";
import { Collection } from "src/types/Collection";
import ProcessGroupModal from "./libraryModals/processGroupModal";
import RemoveItemModal from "../dashboardModals/removeItemModal";

const rowHeaders = ["Process group", "Edit"];

export default function CollectionCard({
  collection,
  processGroups,
}: {
  collection: Collection;
  processGroups: ProcessGroup[];
}) {
  const cards = collection
    ? [
        { key: "Status", value: collection.status || "active" },
        {
          key: "Date Created",
          value: collection.created_at
            ? new Date(collection.created_at).toLocaleDateString("en-US")
            : "unknown",
        },
      ]
    : [];

  const [procesGroupModalOpen, setProcessGroupModalOpen] =
    React.useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);

  const [selectedProcessGroup, setSelectedProcessGroup] =
    React.useState<ProcessGroup | null>(null);
  /* read in and set process groups */
  // const [collectionProcessGroups, setCollectionProcessGroups] =
  //   React.useState<any>([]);

  // React.useEffect(() => {
  //   if (collection) {
  //     const flatCollectionProcessGroupArr = (processGroups as any).map(
  //       (a: any) => Object.values(a)[0]
  //     );

  //     setCollectionProcessGroups(flatCollectionProcessGroupArr);
  //   }
  // }, [processGroups, collection]);

  return (
    <React.Fragment>
      <Paper
        elevation={4}
        sx={{
          backgroundColor: "secondary.dark",
          p: 2,
          height: "500px",
          overflow: "scroll",
        }}
      >
        {!collection && (
          <Typography fontStyle="italic" color="divider" variant="h5" py={2}>
            Select a Collection to view details here
          </Typography>
        )}
        {collection && (
          <React.Fragment>
            <Typography variant="h6" pb={2} align="left" color={"white"}>
              {collection.name}
            </Typography>
            <Grid container spacing={2}>
              {cards.map((card: any) => (
                <Grid item xs={6} key={card.key}>
                  <Card
                    key={card.key}
                    sx={{
                      height: "100%",
                      backgroundColor: "primary.light",
                    }}
                  >
                    <CardContent sx={{ p: 1 }}>
                      <Typography
                        sx={{ fontSize: 16, fontWeight: "bold" }}
                        color="secondary.main"
                      >
                        {card.key}:{" "}
                      </Typography>
                      {collection ? (
                        <Typography variant="h6" color="primary.dark">
                          {card.value}
                        </Typography>
                      ) : (
                        <Skeleton variant="rounded" height={70} />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <br />
            <Card
              sx={{
                mb: 2,
              }}
            >
              <CardContent sx={{ height: "260px" }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {rowHeaders.map((rowHeader: string) => (
                        <TableCell
                          key={rowHeader}
                          align={rowHeader === "Edit" ? "center" : "left"}
                          sx={{ color: "secondary.main" }}
                        >
                          <b>{rowHeader}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {processGroups && processGroups.length > 0 && (
                    <TableBody>
                      {processGroups.map((row: ProcessGroup) => (
                        <TableRow key={row.id}>
                          <TableCell width={"60%"}>{row.name}</TableCell>
                          {/* <TableCell width={"20%"}>
                            TBD
                            {row.sequenceList.length || 0}
                          </TableCell> */}
                          <TableCell align="right" width={"40%"}>
                            <Tooltip
                              title={"Edit Process Group"}
                              placement="left"
                            >
                              <IconButton
                                aria-label="editProcessGroup"
                                color="secondary"
                                onClick={() => {
                                  setSelectedProcessGroup(row as ProcessGroup);
                                  setProcessGroupModalOpen(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title={"Remove Collection"}
                              placement="right"
                            >
                              <IconButton
                                aria-label="deleteProcessGroup"
                                color="secondary"
                                onClick={() => {
                                  setSelectedProcessGroup(row as ProcessGroup);
                                  setRemoveModalOpen(true);
                                }}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {!processGroups && (
                    <TableBody>
                      <TableRow>
                        {/* column for each header */}
                        {rowHeaders.map((c, ic) => (
                          <TableCell key={c + ic}>
                            {/* n = 10 rows */}
                            {[...Array(10)].map((r, ir) => (
                              <Skeleton
                                key={"column:" + ic + "row:" + ir}
                                variant="text"
                                sx={{ width: "100%", fontSize: "1rem" }}
                              />
                            ))}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </CardContent>
            </Card>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              fullWidth
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                setSelectedProcessGroup(null);
                setProcessGroupModalOpen(true);
              }}
            >
              Add Process Group
            </Button>
          </React.Fragment>
        )}
      </Paper>
      {procesGroupModalOpen && (
        <ProcessGroupModal
          open={procesGroupModalOpen}
          setOpen={setProcessGroupModalOpen}
          collectionId={collection.id}
          processGroup={selectedProcessGroup || null}
        />
      )}
      {removeModalOpen && selectedProcessGroup && (
        <RemoveItemModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          itemType={"process_group"}
          item={selectedProcessGroup}
        />
      )}
    </React.Fragment>
  );
}
