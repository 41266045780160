import React, { useContext, useEffect } from "react";

import { Modal, Box, Typography, Stack, Button } from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";

import {
  CreateOrganizationItem,
  FetchOrgMembers,
  UpdateOrganizationItem,
} from "src/API/getUserData";
import DashboardContext from "src/Contexts/dashboardContext";

import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";
import { Member } from "src/types/Member";

const memberSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().min(1, "Member email is required"),
  status: z.enum(["active", "disabled"], {
    errorMap: () => ({ message: "Please select a status" }),
  }),
});

type MemberFormValues = z.infer<typeof memberSchema>;

export default function MemberModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  member: Member | null;
}) {
  const memberForm = useForm<MemberFormValues>({
    resolver: zodResolver(memberSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      status: "active",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = memberForm;

  useEffect(() => {
    if (props.member) {
      memberForm.reset({
        first_name: props.member.first_name || "",
        last_name: props.member.last_name || "",
        email: props.member.email || "",
        status: props.member.status || undefined,
      });
    }
  }, []);

  const { setOrgMembers } = useContext(DashboardContext);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const createMember = () => {
    setModalMessage(LoadingMessage());

    CreateOrganizationItem("member", {
      ...props.member,
      ...memberForm.getValues(),
    })
      .then((response: any) => {
        setModalMessage(SucceessMessage("Members created successfully"));
        /* update the appropriate context so that changes are reflected */
        FetchOrgMembers().then((result: Member[]) => {
          setOrgMembers(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  const updateMember = () => {
    setModalMessage(LoadingMessage());

    UpdateOrganizationItem("member", props.member!.id, {
      ...props.member,
      ...memberForm.getValues(),
    })
      .then((response: any) => {
        setModalMessage(SucceessMessage("Member created successfully"));
        /* update the appropriate context so that changes are reflected */
        FetchOrgMembers().then((result: Member[]) => {
          setOrgMembers(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.member ? "Edit " : "Add a new "} member
            </Typography>

            <FormProvider {...memberForm}>
              <form
                onSubmit={handleSubmit(
                  props.member ? updateMember : createMember
                )}
                noValidate={true}
              >
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Input
                      name="first_name"
                      color="secondary"
                      id="memherFirstName"
                      label="First Name"
                      type="search"
                      variant="standard"
                      fullWidth
                      margin="dense"
                    />
                    <Input
                      name="last_name"
                      color="secondary"
                      id="memberLastName"
                      label="Last Name"
                      type="search"
                      variant="standard"
                      fullWidth
                      margin="dense"
                    />
                  </Stack>
                  <Input
                    name="email"
                    color="secondary"
                    id="memberEmail"
                    label="Email"
                    type="search"
                    variant="standard"
                    fullWidth
                    margin="dense"
                  />

                  {props.member && (
                    <ToggleGroup
                      sx={{ p: 2 }}
                      name="status"
                      options={[
                        {
                          value: "active",
                          label: "Active",
                        },
                        {
                          value: "disabled",
                          label: "Disabled",
                        },
                      ]}
                      fullWidth
                      exclusive
                      size="small"
                      color="secondary"
                      id="status-select"
                      aria-labelledby="buttons-group-label"
                    />
                  )}

                  <br />
                  <br />
                </Box>
                {/* {props.memberOptions.type === "manager" && (
          <Box>
            <Typography
              id="new-member-status"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.isEdit ? "Update" : "Set"} collection permissions for{" "}
              <b>{memberId}</b>:
            </Typography>

            {orgCollectionsActive ? (
              <ModalList>
                {orgCollectionsActive.map((collection: any) => (
                  <li key={`section-${collection.id}`}>
                    <ul>
                      <ListItem
                        key={`item-${collection.id}`}
                        sx={{ padding: 0 }}
                        dense
                      >
                        <ListItemButton
                          role={undefined}
                          // onClick={handleToggle(collection.id)}
                          dense
                        >
                          <ListItemIcon>
                            <Tooltip title={"View"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  collection.id,
                                  "collection_access",
                                  "view"
                                )}
                                id="collection_view"
                                edge="start"
                                checked={
                                  memberOptions.collection_access.view.indexOf(
                                    collection.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": collection.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={"Edit"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  collection.id,
                                  "collection_access",
                                  "edit"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.collection_access.edit.indexOf(
                                    collection.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": collection.id,
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={collection.id} />
                        </ListItemButton>
                      </ListItem>
                    </ul>
                  </li>
                ))}
              </ModalList>
            ) : (
              <LinearProgress color="secondary" />
            )}

            <Typography
              id="new-member-status"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.isEdit ? "Update" : "Set"} team permissions for{" "}
              <b>{memberId}</b>:
            </Typography>
            {orgTeamsActive ? (
              <ModalList>
                {orgTeamsActive.map((team: any) => (
                  <li key={`section-${team.id}`}>
                    <ul>
                      <ListItem
                        key={`item-${team.id}`}
                        sx={{ padding: 0 }}
                        dense
                      >
                        <ListItemButton role={undefined} dense>
                          <ListItemIcon>
                            <Tooltip title={"View"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "view"
                                )}
                                id="collection_view"
                                edge="start"
                                checked={
                                  memberOptions.team_access.view.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={"Edit Membership"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "edit_membership"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.team_access.edit_membership.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={"Edit Process Assignments"}
                              placement="top"
                            >
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "edit_seq_assigments"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.team_access.edit_seq_assigments.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={team.id} />
                        </ListItemButton>
                      </ListItem>
                    </ul>
                  </li>
                ))}
              </ModalList>
            ) : (
              <LinearProgress color="secondary" />
            )}
          </Box>
        )} */}
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </form>{" "}
            </FormProvider>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
