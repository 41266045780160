import React from 'react';
import { 
  useFormContext, 
  Controller,
  ControllerProps,
  FieldValues,
  ControllerRenderProps as HookFormControllerRenderProps,
  UseFormStateReturn
} from 'react-hook-form';
import { 
  FormHelperText, 
  FormControl
} from '@mui/material';

interface FormFieldContextValue {
  name: string;
}

type FieldError = {
  type: string;
  message?: string;
};

type FieldState = {
  invalid: boolean;
  isTouched: boolean;
  isDirty: boolean;
  error?: FieldError;
};

interface FormFieldProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<ControllerProps<TFieldValues>, 'render'> {
  render: (props: {
    field: HookFormControllerRenderProps<TFieldValues>;
    fieldState: FieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
}

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <TFieldValues extends FieldValues>({
  name,
  render,
  ...props
}: FormFieldProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>();

  if (!control) {
    throw new Error('FormField must be used within a FormProvider');
  }

  return (
    <FormFieldContext.Provider value={{ name }}>
      <Controller
        control={control}
        name={name}
        {...props}
        render={({ field, fieldState, formState }) => (
          <FormControl error={!!fieldState.error} fullWidth>
            {render({ field, fieldState, formState })}
            {fieldState.error?.message && (
              <FormHelperText>{fieldState.error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </FormFieldContext.Provider>
  );
};

export { FormField, type FormFieldProps, type FormFieldContextValue };
