import React, { useContext, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Grid from "@mui/material/Grid";
import MemberTable from "./memberList";
import CollectionTable from "./collectionList";
import TeamTable from "./teamList";

import DashboardContext from "src/Contexts/dashboardContext";
import { Box } from "@mui/material";
import PageTitle from "../title";

export default function Admin({ token }: { token: string }) {
  const { orgMembers, orgCollections, orgTeams } = useContext(DashboardContext);

  return (
    <Box overflow={"scroll"} position={"relative"}>
      <PageTitle
        title={"Admin"}
        description={
          "View, create, edit, and delete members, collections, and teams."
        }
      />
      <CssBaseline />
      <Grid container spacing={4} p={4}>
        {" "}
        <Grid item xs={12}>
          <MemberTable members={orgMembers} />
        </Grid>
        <Grid item xs={12}>
          <CollectionTable collections={orgCollections} />
        </Grid>
        <Grid item xs={12}>
          <TeamTable teams={orgTeams} />
        </Grid>
      </Grid>

      <br />
      <br />
      <br />
    </Box>
  );
}
