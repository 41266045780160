import {
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps,
  FormControl,
  FormLabel,
  Tooltip,
  TooltipProps
} from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField } from "./FormField";

interface ToggleOption {
  value: string | number;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipProps?: Partial<TooltipProps>;
}

interface ToggleGroupProps extends Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {
  name: string;
  label?: string;
  helperText?: string;
  options: ToggleOption[];
  defaultValue?: string;
}

export const ToggleGroup = React.forwardRef<HTMLDivElement, ToggleGroupProps>(
  ({ name, label, options, defaultValue, helperText, disabled = false, ...props }, ref) => {
    const formContext = useFormContext();

    if (!formContext) {
      throw new Error('ToggleGroup must be used within a FormProvider');
    }

    const renderToggleButton = (option: ToggleOption) => {
      const button = (
        <ToggleButton
          key={option.value}
          value={option.value}
          disabled={disabled || option.disabled}
        >
          {option.label}
        </ToggleButton>
      );

      if (option.tooltip) {
        return (
          <Tooltip
            key={option.value}
            title={option.tooltip}
            {...option.tooltipProps}
          >
            {button}
          </Tooltip>
        );
      }

      return button;
    };

    return (
      <FormField
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (

          <FormControl component="fieldset">
            {label && (
              <FormLabel component="legend">
                {label}
              </FormLabel>
            )}
            <ToggleButtonGroup
              {...props}
              value={field.value}
              onChange={(_, value) => {

                console.log({ fieldValue: field.value, newValue: value });
                console.log("Before change", value);
                field.onChange(value || '');
                console.log("after change", value);
              }}
              ref={ref}
              color="secondary"
            >
              {options.map(renderToggleButton)}
            </ToggleButtonGroup>
          </FormControl>
        )}
      />
    );
  }
);
