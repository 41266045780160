import React, { useContext, useEffect, useReducer } from "react";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import Overview from "../../Pages/Dashboard/Overview/overview";
import Reporting from "../../Pages/Dashboard/Reporting/reporting";
import Users from "./Admin/Admin";
import Library from "./Library/library";
import Teams from "../../Pages/Dashboard/Teams/teams";

import DashboardMenu from "./sideMenu";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  FetchOrgCollections,
  FetchOrgMembers,
  FetchOrgTeams,
  // GetOrgData,
  // GetUsageData,
} from "../../API/getUserData";
import { getAuth, getIdToken } from "firebase/auth";
import { app } from "../../firebase/firebase";
import UserContext from "../../Contexts/userContext";
import DashboardContext from "../../Contexts/dashboardContext";
import { LoadUserData } from "src/API/loadUserData";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { Collection } from "src/types/Collection";

const auth = getAuth(app);

const INITIAL_DASHBOARD_STATE = {
  orgMembers: [],
  orgCollections: [],
  orgTeams: [],
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
};

const dashboardReducer = (state: any, action: any) => {
  switch (action.type) {
    case "setOrgMembers":
      return {
        ...state,
        orgMembers: action.payload,
      };
    case "setOrgCollections":
      return {
        ...state,
        orgCollections: action.payload,
      };
    case "setOrgTeams":
      return {
        ...state,
        orgTeams: action.payload,
      };
    case "setStepHistory":
      return {
        ...state,
        stepHistory: action.payload,
      };
    case "setOrgProcessGroups":
      return {
        ...state,
        orgProcessGroups: action.payload,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

export default function Dashboard() {
  const [authToken, setAuthToken] = React.useState<string>("");

  const { memberInfo } = useContext(UserContext);
  const { logout } = useContext(UserContext);

  const [dashboardState, dashboardDispatch] = useReducer(
    dashboardReducer,
    INITIAL_DASHBOARD_STATE
  );

  useEffect(() => {
    FetchOrgMembers()
      .then((result: Member[]) => {
        dashboardDispatch({
          type: "setOrgMembers",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });

    FetchOrgTeams()
      .then((result: Team[]) => {
        dashboardDispatch({
          type: "setOrgTeams",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });

    FetchOrgCollections()
      .then((result: Collection[]) => {
        dashboardDispatch({
          type: "setOrgCollections",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });
  }, []);

  // useEffect(() => {
  //   auth.onAuthStateChanged(function (user) {
  //     if (user) {
  //       (async () => {
  //         const token = await getIdToken(user);
  //         setAuthToken(token);

  //         GetOrgData(token, ["members", "collections", "teams"], logout).then(
  //           (result: any) => {
  //             dashboardDispatch({
  //               type: "setOrgMembers",
  //               payload: result.members || [],
  //             });
  //             dashboardDispatch({
  //               type: "setOrgCollections",
  //               payload: result.collections || [],
  //             });
  //             dashboardDispatch({
  //               type: "setOrgTeams",
  //               payload: result.teams || [],
  //             });
  //           }
  //         );
  //         GetUsageData(token, logout).then((result: any) => {
  //           dashboardDispatch({
  //             type: "setStepHistory",
  //             payload: result.usageHistory,
  //           });
  //         });
  //       })();
  //     }
  //   });
  // }, [logout]);

  const currentDashboardState = {
    orgMembers: dashboardState.orgMembers || [],
    setOrgMembers: (orgMembers: any) =>
      dashboardDispatch({ type: "setOrgMembers", payload: orgMembers }),

    orgCollections: dashboardState.orgCollections || [],
    setOrgCollections: (orgCollections: any) =>
      dashboardDispatch({ type: "setOrgCollections", payload: orgCollections }),

    orgTeams: dashboardState.orgTeams || [],
    setOrgTeams: (orgTeams: any) =>
      dashboardDispatch({ type: "setOrgTeams", payload: orgTeams }),

    stepHistory: dashboardState.stepHistory || [],
    setStepHistory: (stepHistory: any) =>
      dashboardDispatch({ type: "setStepHistory", payload: stepHistory }),

    orgProcessGroups: dashboardState.orgProcessGroups || [],
    setOrgProcessGroups: (processGroups: any) =>
      dashboardDispatch({
        type: "setOrgProcessGroups",
        payload: processGroups,
      }),
  };

  /* protected routes for admins and managers in users only */
  const AdminRoute = () => {
    return memberInfo && memberInfo.role === "admin" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  const ManagerRoute = () => {
    return memberInfo && memberInfo.role === "manager" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  return (
    <DashboardContext.Provider value={currentDashboardState}>
      <Box
        component="main"
        sx={{
          display: "flex",
          backgroundColor: "primary.light",
        }}
      >
        <CssBaseline />
        <DashboardMenu />
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route element={<AdminRoute />}>
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/admin" element={<Users token={authToken} />} />
            <Route path="/library" element={<Library />} />
            <Route path="/teams" element={<Teams token={authToken} />} />
          </Route>
          <Route element={<ManagerRoute />}>
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/library" element={<Library />} />
            <Route path="/teams" element={<Teams token={authToken} />} />
          </Route>
        </Routes>
      </Box>
      {/* </Box> */}
    </DashboardContext.Provider>
  );
}
