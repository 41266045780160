import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import { SelectProps as MuiSelectProps } from "@mui/material/Select";
import React from "react";
import { useFormContext } from "react-hook-form";
import { client } from "src/Config/styling";
import { FormField } from "./FormField";

interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface SelectProps extends Omit<MuiSelectProps, 'name' | 'defaultValue'> {
  name: string;
  label?: string;
  options: SelectOption[];
  defaultValue?: string | number;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ name, label, options, defaultValue, disabled = false, ...props }, ref) => {
    const formContext = useFormContext();
    const labelId = `${name}-label`;

    if (!formContext) {
      throw new Error('Select must be used within a FormProvider');
    }

    return (
      <FormField
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <FormControl fullWidth error={!!fieldState.error} disabled={disabled}>
            {label && (
              <InputLabel id={labelId} color="secondary">
                {label}
              </InputLabel>
            )}
            <MuiSelect
              MenuProps={client.Select_MenuProps}
              sx={{
                background:
                  !options || options.length === 0
                    ? "primary.light"
                    : "#FFFFFF",
              }}
              color="secondary"
              {...field}
              {...props}
              inputRef={ref}
              labelId={labelId}
              id={name}
              label={label}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  color={option.disabled ? "divider" : "inherit"}
                  sx={{
                    fontStyle: option.disabled ? "italic" : "normal",
                  }}
                >
                  {option.disabled
                    ? option.label + " - disabled"
                    : option.label}
                </MenuItem>
              ))}
            </MuiSelect>
            {fieldState.error?.message && (
              <FormHelperText>{fieldState.error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  }
);

Select.displayName = "Select";

export { Select, type SelectOption };
