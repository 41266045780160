import { Box, Typography, LinearProgress, Alert, Button } from "@mui/material";

export function LoadingMessage() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {/* {props.info.message} */}
        Loading...
      </Typography>
      <br />
      <LinearProgress color="secondary" />
      <br />
    </Box>
  );
}

export function SucceessMessage(message: string) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"success"}>{"SUCCESS"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {message}
      </Typography>
    </Box>
  );
}

export function ErrorMessage(
  message: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"error"}>{"ERROR"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}
