import { createContext } from "react";
import { Session } from "@supabase/supabase-js";
import { Organization } from "src/types/Organization";
import { Member } from "src/types/Member";

const UserContext = createContext<{
  // user: any;
  session: Session | null;
  orgInfo: Organization | null;
  memberInfo: Member | null;
  login: any;
  logout: any;
  // highlightColor?: string;
  // orgName: string;
  // setOrgName: any;
}>({
  // user: null,
  session: null,
  orgInfo: null,
  memberInfo: null,
  // hasLoginError: false,
  login: (data: any) => null,
  logout: (involuntary?: boolean) => null,
  // highlightColor: "",
  // setHighlightColor: (color: string) => null,
  // orgName: "",
  // setOrgName: (name: string) => null,
});

export default UserContext;
