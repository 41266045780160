import { createContext } from "react";
import { Collection } from "src/types/Collection";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { ProcessGroup } from "src/types/ProcessGroup";

const DashboardContext = createContext<any>({
  orgMembers: [] as Member[],
  setOrgMembers: (members: Member) => null,
  orgCollections: [] as Collection[],
  setOrgCollections: (members: Collection) => null,
  orgTeams: [] as Team[],
  setOrgTeams: (members: Team) => null,
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
  setStepHistory: (history: any) => null,
  orgSeqGroups: [],
  setOrgProcessGroups: (processGroups: ProcessGroup[]) => null,
  orgProcesses: [],
});

export default DashboardContext;
