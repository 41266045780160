import React, { useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  Typography,
} from "@mui/material";
import { Input } from "src/Components/ui/Input";
import { useFormContext } from "react-hook-form";
import { ProcessFormValues } from "./context/ProcessFormContext";
import { Step } from "src/types/Step";

interface StepCardProps {
  index: number;
  step: Step;
  isSelected: boolean;
  onSelect: () => void;
}

const StepCard: React.FC<StepCardProps> = ({
  index,
  step,
  isSelected,
  onSelect,
}) => {
  const {
    formState: { errors },
  } = useFormContext<ProcessFormValues>();

  // Check if there are any errors in the current step
  const stepErrors = errors.steps?.[index];
  const hasErrors = !!stepErrors;

  return (
    <Card
      elevation={4}
      sx={{
        m: 0.5,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        bgcolor: "primary.light",
        boxShadow: (theme) =>
          `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${
            hasErrors
              ? theme.palette.error.main
              : isSelected
              ? theme.palette.secondary.main
              : theme.palette.divider
          }`,
      }}
    >
      <Avatar
        variant="rounded"
        sx={{
          margin: "auto",
          backgroundColor: hasErrors
            ? "error.main"
            : isSelected
            ? "secondary.dark"
            : "divider",
          position: "absolute",
          top: "0px",
          left: "5px",
        }}
      >
        {index + 1}
      </Avatar>
      <CardActionArea onClick={onSelect}>
        <CardContent
          sx={{
            pl: 4,
            borderColor: "secondary",
            borderWidth: isSelected ? 4 : 0,
          }}
        >
          {isSelected ? (
            <>
              <Input
                name={`steps.${index}.title`}
                multiline
                hiddenLabel
                placeholder={`Step ${index + 1} title`}
                color="secondary"
                type="text"
                margin="dense"
                InputProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: "bold",
                    fontStyle: step.title ? "normal" : "italic",
                  },
                  disableUnderline: true,
                }}
              />
              <Input
                name={`steps.${index}.subtitle`}
                multiline
                hiddenLabel
                placeholder={`Step ${index + 1} subtitle`}
                color="secondary"
                type="text"
                margin="dense"
                size="small"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "#999999",
                    fontStyle: step.subtitle ? "normal" : "italic",
                  },
                  disableUnderline: true,
                }}
              />
              <Input
                name={`steps.${index}.description`}
                multiline
                hiddenLabel
                placeholder={`Step ${index + 1} description`}
                color="secondary"
                type="text"
                margin="dense"
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontStyle: step.description ? "normal" : "italic",
                  },
                  disableUnderline: true,
                }}
              />
            </>
          ) : (
            <>
              <Typography
                fontSize={"24px"}
                fontWeight={"bold"}
                color="primary.dark"
                fontStyle={step.title ? "normal" : "italic"}
              >
                {step.title || `Step ${index + 1} title`}
              </Typography>
              <Typography
                fontSize={"20px"}
                color="#999999"
                fontStyle={step.subtitle ? "normal" : "italic"}
              >
                {step.subtitle || `Step ${index + 1} subtitle`}
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="primary.dark"
                fontStyle={step.description ? "normal" : "italic"}
              >
                {step.description || `Step ${index + 1} description`}
              </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StepCard;
