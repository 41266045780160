import { supabase } from "src/supabase/supabaseClient";
import {
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";
import { Organization } from "src/types/Organization";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { Collection } from "src/types/Collection";
import { Process } from "src/types/Process";
import { ProcessGroup } from "src/types/ProcessGroup";

export function FetchUserOrgInfo() {
  return new Promise<Organization>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchUserOrganizationInfo"
      // {
      //   body: { foo: "bar" },
      // }
    );

    if (data) resolve(data as Organization);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      console.log(errorMessage);
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchUserMemberInfo() {
  return new Promise<Member>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchUserMemberInfo"
      // {
      //   body: { foo: "bar" },
      // }
    );

    if (data) resolve(data as Member);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      console.log(errorMessage);
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchOrgMembers() {
  return new Promise<Member[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchOrganizationMembers"
    );

    if (data) resolve(data.members as Member[]);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchOrgTeams() {
  return new Promise<Team[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchOrganizationTeams"
    );

    if (data) resolve(data.teams as Team[]);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchOrgCollections() {
  return new Promise<Collection[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchOrganizationCollections"
    );

    if (data) resolve(data.collections as Collection[]);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function CreateOrganizationItem(itemType: string, item: any) {
  return new Promise<Member | Team | Collection>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `createOrganizationItem?itemType=${itemType}`,
      { body: item }
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function UpdateOrganizationItem(
  itemType: string,
  itemId: string,
  item: any
) {
  return new Promise<Member | Team | Collection>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `updateOrganizationItem?itemType=${itemType}&itemId=${itemId}`,
      { body: item }
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function DeleteOrganizationItem(itemType: string, itemId: string) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `deleteOrganizationItem?itemType=${itemType}&itemId=${itemId}`
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchProcessGroups(collectionId: string) {
  return new Promise<ProcessGroup[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `fetchCollectionProcessGroups?collectionId=${collectionId}`
    );

    if (data) resolve(data as ProcessGroup[]);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function CreateProcessGroup(collectionId: string, processGroup: any) {
  return new Promise<ProcessGroup>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `createOrganizationItem?itemType=process_group&collectionId=${collectionId}`,
      { body: processGroup }
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function UpdateProcessGroup(
  collectionId: string,
  itemId: string,
  processGroup: any
) {
  return new Promise<ProcessGroup>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `updateOrganizationItem?itemType=process_group&itemId=${itemId}&collectionId=${collectionId}`,
      { body: processGroup }
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function DeleteProcessGroup(collectionId: string, itemId: string) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `deleteOrganizationItem?itemType=process_group&itemId=${itemId}&collectionId=${collectionId}`
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchProcesses(processGroupId: string) {
  return new Promise<Process[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `fetchProcessGroupProcesses?processGroupId=${processGroupId}`
    );

    if (data) resolve(data as Process[]);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function FetchProcess(processId: string) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `fetchProcessDetails?processId=${processId}`
    );

    if (data) resolve(data as Process);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function CreateProcess(processGroupId: string, process: any) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `createOrganizationItem?itemType=process&processGroupId=${processGroupId}`,
      { body: process }
    );

    if (data) {
      resolve(data);
    }

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}

export function UpdateProcess(process: Process) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`updateProcess`, {
      body: process,
    });

    if (data) resolve(data as Process);

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context.json();
      reject(errorMessage.error);
    } else if (error instanceof FunctionsRelayError) {
      reject("Relay error: " + error.message);
    } else if (error instanceof FunctionsFetchError) {
      reject("Fetch error: " + error.message);
    }
  });
}


// export function UserOrgInfo(token: string) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(`${process.env.REACT_APP_FUNCTION_BASE_URL}/getUserData?org=true`, {
//       method: "GET", // *GET, POST, PUT, DELETE, etc.
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//         // "Sec-Fetch-Mode": "cors",
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     })
//       .then((response) => {
//         if (response.status === 401) {
//           /* don't logout here beucase when this function is called, the user is not yet logged in and logout is not defined */
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function GetUsageData(token: string, logout: () => null) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(`${process.env.REACT_APP_FUNCTION_BASE_URL}/getUsageData`, {
//       method: "GET", // *GET, POST, PUT, DELETE, etc.
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//         // "Sec-Fetch-Mode": "cors",
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     })
//       .then((response) => {
//         if (response.status === 401) {
//           logout();
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function GetOrgData(
//   token: string,
//   params: string[],
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     /* build a query param string */
//     let queryParams = "?";

//     params.forEach((element) => (queryParams += element + "=true&"));
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/getOrgData${queryParams}`,
//       {
//         method: "GET", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function AddOrgItem(
//   token: string,
//   item: string,
//   body: any,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/addOrgItem${
//         item ? `?${item}=true` : ""
//       }`,
//       {
//         method: "POST", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // member: member,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: JSON.stringify(body),
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function EditOrgItem(
//   token: string,
//   itemType: string,
//   item: string,
//   options: any,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/editRemoveOrgItem${
//         itemType ? `?${itemType}=true` : ""
//       }`,
//       {
//         method: "PUT", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // member: member,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: JSON.stringify({ item: item, itemType: itemType, options }),
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function RemoveOrgItem(
//   token: string,
//   itemType: string,
//   item: string,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(`${process.env.REACT_APP_FUNCTION_BASE_URL}/editRemoveOrgItem`, {
//       method: "DELETE", // *GET, POST, PUT, DELETE, etc.
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//         // "Sec-Fetch-Mode": "cors",
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       body: JSON.stringify({ itemType: itemType, item: item }),
//     })
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function UpdateTeamMembers(
//   token: string,
//   action: string,
//   body: any,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/updateTeamMembers?action=${action}`,
//       {
//         method: "PUT", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // member: member,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: JSON.stringify(body),
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function getSequenceInfo(
//   token: string,
//   sequenceGroup: string,
//   sequenceId: string,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/getSequenceInfo?sequenceGroup=` +
//         sequenceGroup +
//         "&sequenceId=" +
//         sequenceId,
//       {
//         method: "GET", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function AddEditSequence(
//   logout: (involuntary: boolean) => null,
//   action: string,
//   token: string,
//   sequenceGroup: string,
//   sequence: any,
//   sequenceId: string
// ) {
//   return new Promise((resolve, reject) => {
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/addEditSequence?action=` +
//         action +
//         "&sequenceGroup=" +
//         sequenceGroup +
//         (action === "edit" ? "&sequenceId=" + sequenceId : ""), // only inclide sequenceId if this is an edit request

//       {
//         method: "PUT", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//           // "Sec-Fetch-Mode": "cors",
//           // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: JSON.stringify({ sequence: sequence }),
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function AddEditSequeceGroup(
//   token: string,
//   action: string,
//   body: any,
//   logout: (involuntary: boolean) => null
// ) {
//   return new Promise((resolve, reject) => {
//     // Default options are marked with *
//     fetch(
//       `${process.env.REACT_APP_FUNCTION_BASE_URL}/addEditSequenceGroup?action=` +
//         action,
//       {
//         method: "PUT", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//         },
//         body: JSON.stringify(body),
//       }
//     )
//       .then((response) => {
//         if (response.status === 401) {
//           logout(true);
//           resolve({});
//         } else {
//           resolve(response.json());
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }
