import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import UserContext from "../../../Contexts/userContext";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { Member } from "src/types/Member";
import MemberModal from "./adminModals/memberModal";
import RemoveItemModal from "../dashboardModals/removeItemModal";

const defaultMember = {
  id: "",
  org_id: "",
  email: "",
  created_at: "",
  type: "member",
  status: "active",
} as Member;

const rowHeaders = [
  "User",
  "Account Type",
  "Account Status",
  "Member Since",
  "Edit",
];

export default function MemberTable(props: { members: Member[] }) {
  const { memberInfo } = React.useContext(UserContext);

  const [memberModalOpen, setMemberModalOpen] = React.useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);

  const [selectedMember, setSelectedMember] = React.useState<Member | null>(
    null
  );

  /* toggle controls */
  const [memberList, setMemberList] = React.useState<Member[]>(
    props.members.filter((member: Member) => member.status === "active")
  );
  const [hideDisabled, setHideDisabled] = React.useState<boolean>(true);
  const showDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let filteredMembers = props.members.filter(
        (member: Member) => member.status === "active"
      );
      setMemberList(filteredMembers);
    } else {
      setMemberList(props.members);
    }
    setHideDisabled(!hideDisabled);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography fontWeight="bold" color="primary.dark" variant="h5">
          Account Members
        </Typography>
        <FormGroup sx={{ mt: -4 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={hideDisabled}
                onChange={showDisabled}
                name="disabled toggle"
              />
            }
            label={hideDisabled ? "Show Disabled" : "Hide Disabled"}
            labelPlacement="start"
          />
        </FormGroup>
      </Box>

      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table stickyHeader size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {rowHeaders.map((rowHeader: string) => (
                <TableCell
                  key={rowHeader}
                  align={rowHeader === "Edit" ? "center" : "left"}
                  sx={{ color: "secondary.dark" }}
                >
                  <b>{rowHeader}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {memberList.length > 0 ? (
            <TableBody>
              {memberList.map((row: Member) => (
                <TableRow key={row.email + row.role}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.role || "user"}
                      color="secondary"
                      variant={row.role === "admin" ? "filled" : "outlined"}
                      sx={{
                        fontWeight: "bold",
                        color: row.role === "admin" ? "white" : "primary.dark",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row.status || "N/A"}
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "active"
                            ? "secondary.main"
                            : "divider",
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {row.created_at
                      ? new Date(row.created_at).toLocaleDateString("en-US")
                      : "unknown"}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Tooltip title={"Edit Access"} placement="left">
                      <IconButton
                        aria-label="deleteUser"
                        color="secondary"
                        onClick={() => {
                          setSelectedMember(row as Member);
                          setMemberModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove User"} placement="right">
                      <span>
                        <IconButton
                          disabled={memberInfo?.email === row.email}
                          aria-label="deleteUser"
                          color="secondary"
                          onClick={() => {
                            setSelectedMember(row as Member);
                            setRemoveModalOpen(true);
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {rowHeaders.map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(3)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "2.5rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      <br />
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        fullWidth
        onClick={() => {
          setSelectedMember(null);
          setMemberModalOpen(true);
        }}
      >
        Add Member
      </Button>
      {memberModalOpen && (
        <MemberModal
          open={memberModalOpen}
          setOpen={setMemberModalOpen}
          member={selectedMember || null}
        />
      )}
      {removeModalOpen && selectedMember && (
        <RemoveItemModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          itemType={"member"}
          item={selectedMember}
        />
      )}
    </Paper>
  );
}
